<template>
    <dashboard-layout>
        <div class="content-area__body">
            <!-- Job create section -->
            <section class="Job-create-section section-gap-30">

                <div class="job-sections-wowax" v-if="pageState.isContentLoading">
                    <div class="row">
                        <ConversationLoader></ConversationLoader>
                    </div>
                </div>

                <div v-else class="message__wrapper" :class="{'not-found-page': notFoundApplicant}">

                    <ConversationNotFound v-if="notFoundApplicant"></ConversationNotFound>

                    <template v-else>

                        <div class="message__title">
                            <div class="title__wrap" v-if="!showSearchBox">
                                <h3 v-if="showSidebar">{{ $t('Messages') }}</h3>
                                <div v-else-if="isSmallDevice && !showSidebar" class="back-button">
                                    <a href="javascript:void(0);" class="back-button__icon" @click="showSidebarApplicantList"><i class="eicon e-back"></i></a>
                                    <div class="back-button__text">{{ $t('Back') }}</div>
                                </div>

                                <template v-if="company">

                                    <button v-if="showNewConversation" class="control-button control-button--primary has_bg_color has__unread has__new__conversation active" @click="showConversationList">
                                        <div class="control-button__icon">
                                            <i class="eicon e-edit-alt"></i>
                                        </div>
                                        <span>{{ $t('New')}}</span>
                                    </button>

                                    <button v-else class="control-button control-button--primary has_bg_color has__unread has__new__conversation" @click="showNewConversationList">
                                        <div class="control-button__icon">
                                            <i class="eicon e-edit-alt"></i>
                                        </div>
                                        <span>{{ $t('New')}}</span>
                                    </button>

                                </template>

                            </div>
                            <div class="title__wrap" v-else>
                                <h3><i class="eicon e-chat"></i></h3>
                            </div>

                            <div class="header__options">

                                <div class="conversation__header__search" v-if="showSidebar">
                                    <div class="input__wrap" :class="{'show': showSearchBox}">
                                        <input  ref="search" v-model="form.search" type="text" class="form-control" placeholder="Search..." @keyup="filterCandidate">
                                        <span v-if="form.search.length" @click="resetSearch();" class="search__remove"><i class="eicon e-close"></i></span>
                                    </div>
                                    <a href="javascript:void(0);" @click="showSearchBox=!showSearchBox" class="conversation__toggler"><i class="eicon" :class="showSearchBox ? 'e-close': 'e-search'"></i></a>
                                </div>

                                <template v-if="showTopBarToggleButton">
                                    <template v-if="applicant.show_button">
                                        <button v-if="applicant.read" class="control-button control-button--info has_bg_color read__unread__button" @click.prevent="markedAsReadMessage">
                                            <div class="control-button__icon"><i class="eicon e-envelope-fill-alt"></i></div> <span>{{ $t('Mark As Read') }}</span>
                                        </button>
                                        <button v-else class="control-button control-button--info has_bg_color read__unread__button unread_active" @click.prevent="markedAsUnReadMessage">
                                            <div class="control-button__icon"><i class="eicon e-mail"></i></div> <span>{{ $t('Mark As Unread') }}</span>
                                        </button>
                                    </template>

                                    <button @click="toggleChatBoxRightSidebar('attachment')" class="control-button control-button--primary has_bg_color has__unread" :class="{ 'active': openAttachment, 'badge--button': hasAttachment }">
                                        <div class="control-button__icon"><i class="eicon e-attachment"></i></div>
                                        <span>{{ $t('Files') }}</span>
                                    </button>

                                    <button v-if="company" @click="toggleChatBoxRightSidebar('note')" class="control-button control-button--primary has_bg_color has__unread" :class="{ 'active': openNote, 'badge--button': hasNote }">
                                        <div class="control-button__icon"><i class="eicon e-document"></i></div>
                                        <span>{{ $t('Notes') }}</span>
                                    </button>
                                </template>

                            </div>
                        </div>

                        <ConversationSidebar
                            :search="form.search"
                            :showSidebar="showSidebar"
                            :isSmallDevice="isSmallDevice"
                            :infiniteKey="infiniteKey"
                        ></ConversationSidebar>

                        <div class="chat__box" :class="{'hide': showSidebar}">
                            <div class="chat__box__body">
                                <div class="chat__box__head">
                                    <div class="thumb" v-if="company">
                                        <router-link :to="{name: 'applicant.details', params: {id: applicantId}}">
                                            <message-user-picture :src="applicant.image" :alt="applicant.name"></message-user-picture>
                                        </router-link>
                                    </div>
                                    <div class="thumb" v-else>
                                        <a :href="applicant.url" target="_blank">
                                            <message-user-picture :src="applicant.image" :alt="applicant.name"></message-user-picture>
                                        </a>
                                    </div>

                                    <div class="title">
                                        <template v-if="company">
                                            <router-link :to="{name: 'applicant.details', params: {id: applicantId}}">
                                                <p class="name">{{  applicant.name }} </p>
                                            </router-link>
                                            <div class="info">
                                                <p class="job__title">{{  applicant.title }}<span class="state" v-if="applicant.stage">{{ applicant.stage }}</span></p>
                                            </div>
                                        </template>
                                        <template v-else>
                                            <p class="name" style="cursor: inherit">{{ applicant.title }}  |  <span class="state" v-if="applicant.stage">{{ applicant.stage }}</span></p>
                                            <div class="info">
                                                <a :href="applicant.url" target="_blank" style="color: #7d8091">
                                                    <p class="job__title">{{ applicant.name }}</p>
                                                </a>
                                            </div>
                                        </template>
                                    </div>
                                </div>

                                <ConversationChat
                                    :key="$route.fullPath"
                                    :isOpenNoteAndAttachment="isOpenNoteAndAttachment"
                                    @applicantData="getApplicantData">
                                </ConversationChat>

                            </div>

                            <ConversationAttachment
                                v-show="openAttachment">
                            </ConversationAttachment>

                            <ConversationNote
                                v-if="company"
                                v-show="openNote"
                                :key="applicantId"
                                :focusNote="focusNote">
                            </ConversationNote>

                        </div>

                    </template>
                </div>

            </section>
        </div>
    </dashboard-layout>
</template>

<script>
import Vue from "vue";
import {mapActions, mapState} from "vuex";
import {EventBus} from "../../extra/event-bus";
import { CONVERSATION_SIDEBAR_TOGGLE} from "../../constants/events";
import {debounce} from "lodash";
import client from "../../app/api/Client";
import {
    LOAD_CONVERSATION_CANDIDATES,
    LOAD_MESSAGE_NOTIFICATION,
    UPDATE_PAGE_STATE
} from "../../constants/action-type";

const DashboardLayout = () => import("../../layouts/DashboardLayout");
const ConversationAttachment = () => import("../../components/conversations/ConversationAttachment");
const ConversationNote = () => import("../../components/conversations/ConversationNote");
const ConversationChat = () => import("../../components/conversations/ConversationChat");
const ConversationSidebar = () => import("../../components/conversations/ConversationSidebar");
const MessageUserPicture = () => import("../../components/conversations/MessageUserPicture");
const ConversationNotFound = () => import("../../components/conversations/ConversationNotFound");
const ConversationLoader = () => import("../../components/_loaders/ConversationLoader.vue");

export default {
    components: {
        DashboardLayout,
        ConversationChat,
        ConversationAttachment,
        ConversationNote,
        ConversationSidebar,
        ConversationLoader,
        MessageUserPicture,
        ConversationNotFound
    },

    data() {
        return {
            form: {
                search: '',
            },
            applicant: {},
            openNote: false,
            openAttachment: false,
            windowWidth: window.innerWidth,
            showSidebar: true,
            showSearchBox: false,
            focusNote: false,
            infiniteKey: +new Date(),
        }
    },

    computed: {
        ...mapState(['company', 'pageState']),

        isSmallDevice() {
            if (this.windowWidth <= 767) {
                this.openAttachment = false;
                this.openNote = false;
                return true;
            }
            this.showSidebar = true;
            return false;
        },

        showTopBarToggleButton() {
            if (this.isSmallDevice) {
                return !this.showSidebar;
            }
            return true;
        },

        notFoundApplicant() {
            return !this.applicantId;
        },

        applicantId() {
            return this.pageState.applicantId;
        },

        hasAttachment() {
            return this.pageState.attachments?.length > 0;
        },

        hasNote() {
            return this.pageState.notes?.length > 0;
        },

        showNewConversation() {
            return this.pageState.showNewConversation;
        },

        openNoteAndAttachmentSection() {
            return `${this.isSmallDevice}|${this.hasAttachment}|${this.hasNote}}`;
        },

        isOpenNoteAndAttachment() {
            return this.openNote || this.openAttachment;
        }
    },

    methods: {

        ...mapActions([LOAD_MESSAGE_NOTIFICATION, LOAD_CONVERSATION_CANDIDATES, UPDATE_PAGE_STATE]),

        filterCandidate: debounce(async function () {

            this.infiniteKey = +new Date();
            this[UPDATE_PAGE_STATE]({showNewConversation: false});
            await this[LOAD_CONVERSATION_CANDIDATES]({params: {page: 1, ...this.form, 'has-conversation': true}});

        }, 1000),

        async resetSearch() {
            this.form.search = '';

            this.infiniteKey = +new Date();
            this[UPDATE_PAGE_STATE]({showNewConversation: false});
            await this[LOAD_CONVERSATION_CANDIDATES]({params: {page: 1, ...this.form, 'has-conversation': true}});

            this.$nextTick(() => {
                this.$refs.search.focus();
            });
        },

        async markedAsReadMessage() {
            try {
                let {data: {status, message}} = await client().post(`conversation/applicants/${this.applicantId}/message/status`, {'status': 'read'});
                if (status === 'SUCCESS') {

                    this.syncIsUnSeenCandidateListMessage(false);

                    this[LOAD_MESSAGE_NOTIFICATION]();

                    this.$toast.success(message);
                    this.applicant.read = false;
                }
            } catch (error) {
            }
        },

        async markedAsUnReadMessage() {
            try {
                let {data: {status, message}} = await client().post(`conversation/applicants/${this.applicantId}/message/status`, {'status': 'unread'});
                if (status === 'SUCCESS') {

                    this.syncIsUnSeenCandidateListMessage(true);

                    this[LOAD_MESSAGE_NOTIFICATION]();

                    this.$toast.success(message);
                    this.applicant.read = true;
                }
            } catch (error) {
            }
        },

        async getHasConversationApplicantList() {

            await this[LOAD_CONVERSATION_CANDIDATES]({params: {page: 1, ...this.form, 'has-conversation': true}});

            if (!this.company) {
                this.syncIsUnSeenCandidateListMessage(false);
            }

            this[UPDATE_PAGE_STATE]({isContentLoading: false})

        },

        syncIsUnSeenCandidateListMessage(is_unseen) {
            let candidates = this.pageState.candidates;

            candidates.map((candidate) => {
                if (candidate.id === parseInt(this.pageState.applicantId)) {
                    candidate.message.is_unseen = is_unseen;
                }
            });

            this[UPDATE_PAGE_STATE]({candidates})
        },

        showSidebarApplicantList() {
            this.showSidebar = true;
        },

        showConversationList() {
            this.showSidebar = true;
            this[UPDATE_PAGE_STATE]({showNewConversation: false});
        },

        showNewConversationList() {
            this.showSidebar = true;
            this[UPDATE_PAGE_STATE]({showNewConversation: true});
        },

        getApplicantData(applicant) {
            this.applicant = applicant;
        },

        toggleChatBoxRightSidebar(param) {
            if (param === 'attachment') {
                this.openNote = false;
                this.openAttachment = !this.openAttachment;
            }
            if (param === 'note') {
                this.openAttachment = false;
                this.openNote = !this.openNote;

                if (this.openNote) {

                    this.focusNote = !this.focusNote;

                    Vue.nextTick(() => {
                        const div = document.getElementById('noteBox');
                        div.scrollTop = div.scrollHeight;
                    });
                }
            }
        },

        onWindowWidthResize(e) {
            this.windowWidth = window.innerWidth;
        },

        setDefaultPageState() {
            this[UPDATE_PAGE_STATE]({
                candidates: [],
                applicantId: null,
                candidatePage: null,
                candidateLastPage: null,
                totalCandidate: null,
                showNewConversation: false,
                attachments:[],
                notes: [],
                draft_notes: [],
                managers: [],
                isContentLoading: true
            })
        },

        changeNoteAndAttachmentStatus() {
            if (this.isSmallDevice) {
                this.openAttachment = false;
                this.openNote = false;
            } else {
                if (this.hasNote) {
                    this.openNote = true;
                    this.openAttachment = false;
                } else if (this.hasAttachment) {
                    this.openNote = false;
                    this.openAttachment = true;
                } else {
                    this.openNote = false;
                    this.openAttachment = false;
                }
            }
        }
    },

    created() {

        this.setDefaultPageState();

        this.getHasConversationApplicantList();

        this.changeNoteAndAttachmentStatus();

        this[UPDATE_PAGE_STATE]({showNewConversation: !!this.$route.params.new});

        EventBus.$on(CONVERSATION_SIDEBAR_TOGGLE, () => {
            this.showSidebar = !this.showSidebar;
            this.showSearchBox = false;
        });

        window.addEventListener("resize", this.onWindowWidthResize);
    },

    watch: {
        async '$route'(to, from) {

            this[UPDATE_PAGE_STATE]({isContentLoading: true})

            this[UPDATE_PAGE_STATE]({applicantId: to.params.id});
            if (typeof this.pageState.applicantId == 'undefined') {
                await this[LOAD_CONVERSATION_CANDIDATES]({params: {page: 1, ...this.form, 'has-conversation': true}});
            }

            if (!this.company) {
                this.syncIsUnSeenCandidateListMessage(false);
            }

            this[UPDATE_PAGE_STATE]({isContentLoading: false})
        },

        openNoteAndAttachmentSection: function () {
            this.changeNoteAndAttachmentStatus();
        }

    },

    destroyed() {
        window.removeEventListener("resize", this.onWindowWidthResize);
        EventBus.$off(CONVERSATION_SIDEBAR_TOGGLE);
    },
}
</script>

<style src="../../css/conversations.css" scoped></style>
