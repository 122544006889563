import { render, staticRenderFns } from "./Conversations.vue?vue&type=template&id=db723fc4&scoped=true&"
import script from "./Conversations.vue?vue&type=script&lang=js&"
export * from "./Conversations.vue?vue&type=script&lang=js&"
import style0 from "../../css/conversations.css?vue&type=style&index=0&id=db723fc4&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "db723fc4",
  null
  
)

export default component.exports